// Datums-Unterstützung
import {
  addDays,
  format,
  formatISO,
  isDate,
  isValid,
  parseISO,
  parseJSON,
} from "date-fns";
import { de } from "date-fns/locale";

export interface DatumsRange {
  start: string;
  end: string;
}

export default class Datum {
  static parse(inDate: string | null): Date | null {
    if (null === inDate || undefined === inDate) return null;

    let date: Date | null = null;

    if (inDate.length <= 10) date = parseISO(inDate);
    else if (inDate[10] == "T") date = parseISO(inDate);
    else if (inDate[10] == " ") date = parseJSON(inDate);

    // console.log("parsing ...", date, dt)

    if (isDate(date) && isValid(date)) return date;
    return null;
  }

  static fmtTagDatumZeit(inDate: Date | null, dflt = "-/-"): string {
    if (null === inDate) return dflt;

    return format(inDate, "eeeeee, d.M.yy (HH:mm)", { locale: de });
  }

  // Get a Text String and try to parse and reformat it into an ISO/SQL Date
  static datumISO(date: string): string {
    let dt = Datum.parse(date);
    if (null == dt) dt = new Date();

    return formatISO(dt, { representation: "date" });
  }

  // Get a ISO Date String and try to parse and reformat it into an German Date
  static datumDeutsch(date: string): string {
    if (!date) return "";

    const [year, month, day] = date.split("-");
    return `${day}.${month}.${year}`;
  }

  // Sort two ISO/SQL Date Strings
  static sort(a: string, b: string): DatumsRange {
    a = this.datumISO(a);
    b = this.datumISO(b);

    return a < b ? { start: a, end: b } : { start: b, end: a };
  }

  // Fill an array of Date String based on a Range
  static spread(range: DatumsRange): string[] {
    const ret = new Array<string>();

    ret.push(range.start);
    let date = parseISO(range.start);

    for (let max = 0; max < 1000; max++) {
      date = addDays(date, 1);
      const next = formatISO(date, { representation: "date" });
      if (next > range.end) break;

      ret.push(next);
    }

    return ret;
  }

  static p5d = (date: string | null): string => {
    return Datum.p5dD(date, "");
  };

  static p5dD = (date: string | null, dflt: string): string => {
    if (null === date || undefined === date) return dflt;

    // console.log("p5dD: ", date, dflt)
    const dt = Datum.parse(date);
    if (null === dt) return dflt;

    // console.log("dt: ", dt)
    const ret = format(dt, "dd.MM.yyyy");

    // console.log("From", date, "to", ret)
    return ret;
  };

  static p5sdD = (date: string | null, dflt: string): string => {
    if (null === date || undefined === date) return dflt;

    // console.log("p5dD: ", date, dflt)
    const dt = Datum.parse(date);
    if (null === dt) return dflt;

    // console.log("dt: ", dt)
    const ret = format(dt, "d.M.yy");

    // console.log("From", date, "to", ret)
    return ret;
  };

  static p5sdDD = (date: string | null, dflt: string): string => {
    if (null === date || undefined === date) return dflt;

    // console.log("p5dD: ", date, dflt)
    const dt = Datum.parse(date);
    if (null === dt) return dflt;

    // console.log("dt: ", dt)
    const ret = format(dt, "d.M.yyyy");

    // console.log("From", date, "to", ret)
    return ret;
  };
}
